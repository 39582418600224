import { InjectionToken } from '@angular/core';
import { Observable } from 'rxjs';
import { AnyObject } from '@oper-client/shared/data-model';
import { AiValidationFieldMappings } from '@oper-client/shared/configuration';

export const AI_VALIDATION_SERVICE = new InjectionToken<IAiValidationServiceService>('AI_VALIDATION_SERVICE');

export interface IAiValidationServiceService {
	getConfig(): Observable<{fieldMapping: AiValidationFieldMappings; supportedDocuments: string[]}>;
	
	clearValidationResults(): void;

	triggerValidation(loanRequestId: number, proofId: number, docId: number): Observable<{ id:string, status: string }>;
	
	getValidationResult(loanRequestId: number, proofId: number, docId: number, validationId: string): Observable<{ id: string, status: string, payload:AnyObject<string> }>;
}
