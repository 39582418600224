import { Inject, Injectable, signal } from '@angular/core';
import { API_SERVICE, IAiValidationServiceService, IApiService } from '@oper-client/shared/data-access';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AnyObject } from '@oper-client/shared/data-model';
import { AiValidationFieldMappings } from '@oper-client/shared/configuration';
import { AI_VALIDATION_CONFIG } from '@oper-client/shared/ai-validation/data-access';

@Injectable()
export class AiValidationService implements IAiValidationServiceService {
	$validationResults = signal([]);

	constructor(
		@Inject(API_SERVICE) private readonly apiService: IApiService,
		@Inject(AI_VALIDATION_CONFIG)
		private readonly config: {
			fieldMapping: AiValidationFieldMappings;
			supportedDocuments: string[];
		}
	) {}

	getConfig(): Observable<{ fieldMapping: AiValidationFieldMappings; supportedDocuments: string[] }> {
		return of(this.config);
	}

	triggerValidation(
		loanRequestId: number,
		proofId: number,
		docId: number
	): Observable<{
		id: string;
		status: string;
	}> {
		return this.apiService
			.post(`/api/loan-requests/${loanRequestId}/proof/${proofId}/documents/${docId}/ai_verifications/`)
			.pipe(map((res) => ({ id: res.celeryTaskId, status: res.status })));
	}

	getValidationResult(
		loanRequestId: number,
		proofId: number,
		docId: number,
		validationId: string
	): Observable<{
		id: string;
		status: string;
		payload: AnyObject<string>;
	}> {
		const existingResult = this.$validationResults().find((el) => el.celeryTaskId === validationId);
		if (existingResult) {
			return of(existingResult);
		}
		return this.apiService.get(`/api/loan-requests/${loanRequestId}/proof/${proofId}/documents/${docId}/ai_verifications`).pipe(
			map((res) => {
				const searchedRes = res.find((item) => item.celeryTaskId === validationId);
				if (searchedRes && searchedRes.status === 'finished') {
					this.$validationResults.update((prev) => [...prev, searchedRes]);
				}
				return searchedRes
					? {
							id: searchedRes.celeryTaskId,
							status: searchedRes.status,
							payload: searchedRes.payload,
						}
					: null;
			})
		);
	}

	clearValidationResults() {
		this.$validationResults.set([]);
	}
}
