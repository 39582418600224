import { AiValidationFieldMappings } from '@oper-client/shared/configuration';

const configuration: { fieldMapping: AiValidationFieldMappings; supportedDocuments: string[] } = {
	fieldMapping: {
		firstName: {
			section: 'client',
			subSection: 'identity',
			propertyPath: 'firstName',
		},
		middleName: {
			section: 'client',
			subSection: 'identity',
			propertyPath: 'middleName',
		},
		lastName: {
			section: 'client',
			subSection: 'identity',
			propertyPath: 'lastName',
		},
		countryCode: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'employer.address.country.definition',
			resource: 'country',
		},
		city: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'employer.address.city',
		},
		street: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'employer.address.street',
		},
		houseNumber: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'employer.address.houseNumber',
		},
		zipCode: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'employer.address.zipCode',
		},
		startDate: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'startDate',
		},
		employerName: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'employer.name',
		},
		contractType: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'contractType.definition',
			resource: 'employment-contract-type',
		},
		grossMonthlyIncome: {
			section: 'client',
			subSection: 'income',
			propertyPath: 'amount',
			type: 'currency',
		},
		birthDate: {
			section: 'client',
			subSection: 'identity',
			propertyPath: 'birthDate',
		},
		idCardNumber: {
			section: 'client',
			subSection: 'identity',
			propertyPath: 'idCardNumber',
		},
	},
	supportedDocuments: ['paySlip'],
};

export default configuration;
